import { DOCUMENT } from '@angular/common';
import { Injectable, RendererFactory2, inject } from '@angular/core';

const FIN_PAGE_CONTENT_PADDING_BOTTOM_CSS_VAR_NAME = '--fin-page-content-padding-bottom';

@Injectable({
  providedIn: 'root',
})
export class FinLayoutService {
  private readonly document = inject(DOCUMENT);
  private readonly defaultPageContentPaddingBottom: string;
  private readonly rendererFactory2 = inject(RendererFactory2);
  private readonly renderer = this.rendererFactory2.createRenderer(null, null);

  constructor() {
    this.defaultPageContentPaddingBottom = getComputedStyle(this.document.documentElement)
      .getPropertyValue(FIN_PAGE_CONTENT_PADDING_BOTTOM_CSS_VAR_NAME)
      .trim();
  }

  setAuthLayoutBackgroundColor(): void {
    this.renderer.addClass(this.document.body, 'auth-layout-background');
  }

  removeAuthLayoutBackgroundColor(): void {
    this.renderer.removeClass(this.document.body, 'auth-layout-background');
  }

  addPageContentPaddingBottom(additionalPaddingRem: number): void {
    const currentPaddingValue = getComputedStyle(this.document.documentElement)
      .getPropertyValue(FIN_PAGE_CONTENT_PADDING_BOTTOM_CSS_VAR_NAME)
      .trim();
    const currentPaddingRem = parseFloat(currentPaddingValue) || 0;
    const newPaddingRem = currentPaddingRem + additionalPaddingRem;

    this.document.documentElement.style.setProperty(
      FIN_PAGE_CONTENT_PADDING_BOTTOM_CSS_VAR_NAME,
      `${newPaddingRem}rem`
    );
  }

  resetPageContentPaddingBottom(): void {
    this.document.documentElement.style.setProperty(
      FIN_PAGE_CONTENT_PADDING_BOTTOM_CSS_VAR_NAME,
      this.defaultPageContentPaddingBottom
    );
  }
}
